import {
  CardElement,
  useStripe,
  useElements,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import swal from "sweetalert";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
} from "reactstrap";
import {
  createNewCard,
  createTrialSubscription,
  getCardList,
  updatePaymentMethod,
} from "../../lib/helper/charge";
import { editIsActiveSecondCard, editIsSecondCard, patientAddNewCard, removeActiveSeamless } from "../../lib/helper/patient";

import WeighLossMemberShip from "../../data/weightloss/newSubscription.json";
import Cookies from "js-cookie";

const cardStyle = {
  style: {
    hidePostalCode: true,
    base: {
      color: "#000000",
      fontFamily: "Jost",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      letterSpacing: "0px",
      lineHeight: 1.8,

      "::placeholder": {
        color: "#8898aa",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};


const VisitCreateCardUnused = ({ customer_id, setSecond_card, subscription_obj, test_name, onlyConfirm }) => {

  const [cardFilled, setCardFilled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('')

  const stripe = useStripe();
  const elements = useElements();

  const payment = async () => {
    setLoading(true);
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardElement);
    let token_val = await stripe.createToken(cardElement);

    if (token_val.error?.message) {
      setError(token_val.error?.message);
      setLoading(false); return
    }

    let payload = {
      token: token_val.token.id,
      email: window.sessionStorage.getItem("email"),
    }

    const upadteTheCard = await patientAddNewCard(payload)

    const subs_id = WeighLossMemberShip.find((x) => x.testName === test_name && x.interval === 1)

    if (upadteTheCard.status == "success") {
      const unixTime = moment(subscription_obj.next_charge_date).format("X");

      let payloadW = {
        customer_id: upadteTheCard.data.id,
        items: [
          {
            price: subs_id.key,
          },
        ],

        trial: unixTime,
        coupon: "no",
      };

      const remove_subscriptions = await removeActiveSeamless(subscription_obj.subscription_id)

      if (remove_subscriptions.status === "success") {
        const createSubTestW = await createTrialSubscription(
          payloadW
        );
        if (createNewCard.subscriptionId) {
          swal("Completed!", "Card added successfully!", "success");
          const second_Card = await editIsActiveSecondCard()
          setSecond_card(false);
          setLoading(false);
        }
      }

    } else {
      swal(upadteTheCard.error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (elements != null) {
      const cardElement = elements.getElement(CardElement);
      if (cardElement && cardElement != null) {
        cardElement.on("change", function (event) {
          if (event.complete) {
            setCardFilled(true);
          } else {
            setCardFilled(false);
          }
        });
      }
    }
  }, [elements]);


  return (
    <div className="row mt-2">
      <div className="col-md-12">
        <div>
          <CardElement options={cardStyle} className="form-control pt-2" />
        </div>
        {loading == true ? (
          <button className="btn btn-lg-secondary w-100 mt-3" disabled>
            Loading...
          </button>
        ) : (
          <button
            onClick={(e) => {
              payment();
            }}
            className="btn btn-lg-secondary w-100 mt-3"
            disabled={cardFilled == false}
          >
            Confirm Payment Method
          </button>
        )}
        {error && <p className='text-danger text-center my-3'>{error}</p>}
      </div>
    </div>
  );
};
export default VisitCreateCardUnused;
