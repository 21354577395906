import React, { useState, useEffect } from 'react'
import { Layout, Menu } from 'antd';
import Link from 'next/link'
import Cookies from "js-cookie"
import {
    UserOutlined,
    ExperimentOutlined,
    LoginOutlined,
    CommentOutlined,
    CreditCardOutlined
} from '@ant-design/icons';
import { useRouter } from 'next/router';
import { getPatientProfile } from '../../lib/helper/patient';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import VisitCreateCardUnused from "../createCard/visitCreateCardUnused";

const { Sider, Content } = Layout;

const Sidebar = (props) => {


    // STRIPE CONFIG
    const stripeOptions = { fonts: [{ cssSrc: "https://fonts.googleapis.com/css2?family=Jost&display=swap" }] };
    const stripePromise = loadStripe(
        "pk_live_51MuGnTH7sZ29UScpdbl17QRi8U4xsgXQgRPmC38RIhDPpDoK7434w00xhLaUf7GxrtxNA7Qx5R4VjLOYa9nCdt1N00gTogBY3l"
    );


    const router = useRouter()

    const [paymentConfirmModalOpen, setPaymentConfirmModalOpen] = useState(false)
    const [desktopData, setDesktopData] = useState([])
    const [verify, setVerify] = useState(false)

    const currentPath = router.pathname;
    const activeKey = desktopData.find(item => item.route === currentPath)?.key;

    const with_msg = [
        // {
        //     key: '5',
        //     icon: (<CreditCardOutlined />),
        //     label: (<a onClick={() => setPaymentConfirmModalOpen(true)}>Payment Method</a>)
        // },
        {
            key: '1',
            icon: <Link href="/visits"><ExperimentOutlined /></Link>,
            route: "/visits",
            label: (<Link href="/visits">Treatments</Link>),
        },
        {
            key: '2',
            route: "/information",
            icon: (<Link href="/information"><UserOutlined /></Link>),
            label: (<Link href="/information">Account</Link>),
        },
        {
            key: '4',
            icon: (<LoginOutlined />),
            label: (<a onClick={() => handleLogout()}>Logout</a>),
        },

    ]

    const without_msg = [
        // {
        //     key: '5',
        //     icon: (<CreditCardOutlined />),
        //     label: (<a onClick={() => setPaymentConfirmModalOpen(true)}>Payment Method</a>)
        // },
        {
            key: '1',
            icon: <Link href="/visits"><ExperimentOutlined /></Link>,
            route: "/visits",
            label: (<Link href="/visits">Treatments</Link>),
        },
        {
            key: '2',
            route: "/information",
            icon: (<Link href="/information"><UserOutlined /></Link>),
            label: (<Link href="/information">Account</Link>),
        },
        {
            key: '3',
            route: "/messages/patient",
            icon: (<Link href="/messages/patient"><CommentOutlined /></Link>),
            label: (<Link href="/messages/patient">Support</Link>),
        },
        {
            key: '4',
            icon: (<LoginOutlined />),
            label: (<a onClick={() => handleLogout()}>Logout</a>),
        },


    ]

    const handleLogout = () => {
        window.sessionStorage.clear();

        Object.keys(Cookies.get()).forEach((element) => {
            Cookies.remove(element);
        })

        router.push("/login");
    }

    useEffect(async () => {
        const profile = await getPatientProfile()
        if (profile) {
            if (parseInt(profile.is_healthie) == 0) {
                setDesktopData(with_msg)
            } else {
                setDesktopData(without_msg)
            }

            if (profile.is_user_verified != null) {
                setVerify(true)
            } else {
                router.push("/information")
            }
        }
    }, [router.pathname])

    return (
        <>
            <Modal isOpen={paymentConfirmModalOpen} size={"lg"} centered toggle={() => setPaymentConfirmModalOpen(false)}>
                <ModalHeader>
                    Update Account Information
                </ModalHeader>
                <ModalBody>
                    <Elements stripe={stripePromise} options={stripeOptions}>
                        <VisitCreateCardUnused onlyConfirm={true} />
                    </Elements>
                </ModalBody>


            </Modal>
            <Layout>
                {
                    verify === true && (
                        <Sider trigger={null} theme="light" style={{ position: "fixed", height: "100vh", background: "#FFFFFF" }} className="desktop-screen main-side-bar-1 " width="250">
                            <div className="logo img-logos mt-3" >
                                <img src="/images/logo.webp" className="sidebar-logo-ant" />
                                <hr />
                            </div>
                            <Menu
                                theme="light"
                                mode="inline"
                                defaultSelectedKeys={[activeKey]}
                                style={{
                                    fontSize: "17px",
                                    fontWeight: "400",
                                    background: "#ffffff"

                                }}
                                items={desktopData}
                            />
                        </Sider>
                    )
                }
                <Layout className="site-layout main-bg-site">
                    <Content
                        className={`${verify === true ? "sidebar-antd-size" : "sidebar-antd-size-verify"} ${currentPath === "/messages/patient" && ("new-message-ui")}`}
                    >
                        {props.children}
                        {
                            verify === true && (
                                <div className="sidebar-menu-mobile mobile-view-1">
                                    {
                                        desktopData.map((ele) => {
                                            if (ele.key === "4") return
                                            return (
                                                <div key={ele.key} className={`${activeKey === ele.key && ("active-menu")} Mobile-menu-child`}>
                                                    {ele.icon}
                                                </div>
                                            )
                                        })
                                    }
                                    {/* I do this because the handleLogout function call does not work correctly when mapping it. */}
                                    <div className='Mobile-menu-child' key={'4'} onClick={handleLogout}>
                                        <LoginOutlined />
                                    </div>
                                </div>)
                        }
                    </Content>
                </Layout>
            </Layout>
        </>

    )
}

export default Sidebar